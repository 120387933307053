<template>
  <div class="container-fluid">
  	<div class="page-header d-flex justify-content-between align-items-center">
  		<strong>รายการหวย</strong>
  		<button class="btn btn-sm btn-link" @click="addMarket">เพิ่มหวย</button>
  	</div>
    <hr class="mt-0 p-0">

    <div v-for="group in groups" :key="group._id" class="mt-3">
      <h5 class="text-primary mb-1">{{group.groupTitle}}</h5>
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th colspan="3" class="text-center p-1">หวย</th>
            <th class="text-center p-1">กลุ่ม</th>
            <th class="text-center p-1">วันที่เปิดรับ</th>
            <th class="text-center p-1">เวลาเปิด</th>
            <th class="text-center p-1">เวลาปิด</th>
            <th class="text-center p-1">สถานะ</th>
            <th class="text-center p-1">ตั้งค่า</th>
          </tr>
        </thead>
        <tbody>
          <tr class="col-md-3" v-for="(market, index) in group.markets">
            <td width="40">{{index+1}}</td>
            <td width="70" class="py-1 p-0">
              <img v-if="market.imageIcon" :src="market.imageIcon" style="width: 60px">
            </td>
            <td width="200">{{market.marketTitle}}</td>
            <td width="150">{{group.groupTitle}}</td>
            <td class="text-center">{{openDays(market)}}</td>
            <td width="120" class="text-center text-info">{{market.openTime}}</td>
            <td width="120" class="text-center text-danger">{{market.closeTime}}</td>
            <td width="150" class="text-center" :class="market.statusConfig.textColor">{{market.statusConfig.text}}</td>
            <td width="120">
              <b-link class="card-link" :to="{ name: 'MarketInfo', params: { marketId: market._id } }">รายละเอียด <i class="fas fa-arrow-right" style="font-size: 90%"></i></b-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <LottoMarketTitleModal :data="addNewMarket" :is-show="isShowMarketModal" @close="isShowMarketModal=false" />
  </div>
</template>

<script>
import _ from 'lodash'

import MarketService from '@/services/marketService'

import MarketShortCard from './components/MarketShortCard.vue'
import LottoMarketTitleModal from './components/LottoMarketTitleModal.vue'
import { marketTitleInterface } from '@/interfaces/Market'
import { marketStatus, daysOfWeek } from '@/configs/market.config'

export default {
	components: {
    MarketShortCard,
		LottoMarketTitleModal
	},
	data() {
		return {
      marketList: [],
			isShowMarketModal: false,
      addNewMarket: marketTitleInterface
		}
	},
  computed: {
    groups() {
      if(this.marketList) {

        const groups = _.orderBy(this.$store.state.marketGroups, ['sort'], ['asc'])
        .map((group)=>{
          const markets = this.marketList.filter((market)=>{
            return market.groupId === group._id
          })
          .map((market)=>{
            const status = marketStatus.find((c)=>{
              return c.code === market.status
            })
            market['statusConfig'] = status

            return market
          })
          group['markets'] = _.orderBy(markets, ['sort'], ['asc'])
          return group
        })

        return groups.filter(g=>g.markets.length)
      }else{

        return null
      }
    }
  },
	methods: {
    loadMarkets() {
      MarketService.getMarkets().then((response)=>{
        console.log('response', response)
        if(response.success) {
          this.marketList = response.data
        }
      })
    },
    addMarket() {
      this.addNewMarket = JSON.parse(JSON.stringify(marketTitleInterface))
      this.isShowMarketModal = true
    },
    groupName(groupId) {
      const group = this.$store.state.marketGroups.find((g)=>{
        return g._id === groupId
      })

      if(group) {
        return group?.groupTitle
      }else{
        return 'ไม่มีกลุ่ม'
      }
    },
    openDays(market) {
      if(market) {
        const dayThs = market.openDays.map((dCode)=>{
          let day = daysOfWeek.find((d)=>{
            return d.code === dCode
          })
          if(day) {
            return day.text
          }else{
            return dCode
          }
        })
        return dayThs.join(', ')
      }else{
        return null
      }
    }
	},
  mounted() {
    this.loadMarkets()
    this.$store.dispatch('reloadMarketGroups')
  }
}
</script>

<style lang="scss" scoped>
.container {
	.alert-secondary {
		background-color: #F5F5F5;
		border: 0;
	}
}
</style>
