<template>
  <div class="card" :class="statusBorder">
    <div class="d-flex justify-content-between">
      <h4 class="card-title my-3 ml-3 p-0"><i class="fas" :class="[statusTextColor, statusIcon]"></i> {{ title }}</h4>
      <span>
        <img v-if="srcIcon" :src="srcIcon" style="width: 80px; height: 48px;">
      </span>
    </div>
    <div class="card-body pt-0">
      <div class="alert alert-secondary">
        <b-card-text>
          <span class="text-primary">กลุ่ม:</span> {{ marketGroup }}
        </b-card-text>
        <b-card-text>
          <span class="text-primary">วันที่เปิดแทง:</span> {{ openDays }}
        </b-card-text>
        <b-card-text>
          <span class="text-primary">เปิดรับแทง:</span> {{ times }}
        </b-card-text>
      </div>
      <div class="text-right">
        <b-link class="card-link" :to="{ name: 'MarketInfo', params: { marketId: data._id } }">รายละเอียด <i class="fas fa-arrow-right" style="font-size: 90%"></i></b-link>
      </div>
    </div>
  </div>
</template>
<script>
import { marketStatus, daysOfWeek } from '@/configs/market.config'

export default {
  name: 'MarketShortCard',
  props: {
    data: {
      type: [Object, String],
      default: null
    }
  },
  computed: {
    title() {
      if(this.data.marketTitle){
        return this.data.marketTitle
      }else{
        return '-'
      }
    },
    statusConfig() {
      if(this.data){
        const config = marketStatus.find((c)=>{
          return c.code === this.data.status
        })
        if(config) {
          return config
        }else{
          return null
        }
      }else{
        return null
      }
    },
    statusTextColor() {
      const config = this.statusConfig
      if(config){
        return config.textColor
      }else{
        return ''
      }
    },
    statusIcon() {
      const config = this.statusConfig
      if(config){
        return config.icon
      }else{
        return ''
      }
    },
    statusBorder() {
      const config = this.statusConfig
      if(config){
        return config.borderClass
      }else{
        return ''
      }
    },
    marketGroup() {
      if(this.data) {
        const allGroups = this.$store.state.marketGroups
        const group = allGroups.find((g)=>{
          return g._id === this.data.groupId
        })

        if(group !== undefined) {
          return group.groupTitle
        }else{
          return this.data.groupId
        }
      }else{
        return null
      }
    },
    openDays() {
      if(this.data) {
        const dayThs = this.data.openDays.map((dCode)=>{
          let day = daysOfWeek.find((d)=>{
            return d.code === dCode
          })
          if(day) {
            return day.text
          }else{
            return dCode
          }
        })
        return dayThs.join(', ')
      }else{
        return null
      }
    },
    times() {
      if(this.data) {
        return `${this.data.openTime} - ${this.data.closeTime}`
      }else{
        return null
      }
    },
    srcIcon() {
      if(this.data) {
        return this.data.imageIcon
      }else{
        return null
      }
    }
  },
  methods: {
    view() {
      this.$router.push({
        name: 'MarketInfo',
        params: {
          marketId: this.data._id
        }
      })
    }
  }
}
</script>
